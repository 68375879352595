<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Delete frameworks</h3>

	<p>A System Admin, System Editor, Framework Admin, and Framework Editor have the ability and permissions to edit and delete frameworks.
		<ol>
			<li>Choose the <nobr><v-icon small>fas fa-edit</v-icon></nobr> <strong>EDIT</strong> icon at the top of the framework.</li>
			<li>Select the name of the framework to reveal the <strong>Edit Framework</strong> button.</li>
			<li>From the menu, select <nobr><v-icon medium> fa-solid fa-ellipsis</v-icon></nobr> <strong>MORE OPTIONS.</strong></li>
			<li>Find <strong>DELETE FRAMEWORK</strong></li>
		</ol>
	</p>

	<p>The following pop up window will appear:
		<br>
		<img srcset="/docimages/delete_framework.png 1.5x" class="k-help-img block">
		<ul>
			<li>Either choose <strong>CANCEL</strong> or <strong>DELETE.</strong></li>
		</ul>
	</p>

	<p>NOTE: There are alternative options to remove visibility to a framework and ways to fix a framework other than deleting it.
		<ul>
			<li>The <span v-html="link('adoption_status','adoption status')"></span> for a Framework can be set to <strong>DEPRECATED</strong> rather than needing to delete a framework. </li>
			<li>A Framework can also be split,  cleaned up, or <span v-html="link('batch_update','batch updated.')"></span></li>
		</ul>
	</p>


</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>